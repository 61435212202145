<template>
  <!-- 用户协议 -->
  <div class="container">
    <p>
      【签约主体】本协议由您与易服立达平台的经营者北京易服立达科技有限公司共同缔结履行，本协议对双方均具有法律约束力。
    </p>
    <p>
      【审慎阅读】在您向易服立达平台（以下简称“易服立达”或“本平台”）提交申请注册前，您应当阅读本《易服立达用户注册服务协议》（以下简称“本协议”），本协议内容包括协议正文及所有平台已经发布的各类规则。所有规则为本协议不可分割的一部分，与本协议正文具有同等法律效力。<span
        class="bold"
        >请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、权利义务、争议解决和法律适用条款。</span
      >限制、免责条款可能以黑体加粗形式提示您注意，您应重点阅读。如果您对本协议的条款有疑问的，可与本平台指定的对接人员进行询问，对接人员将向您解释条款内容。
    </p>
    <p>【签约动作】一旦您点击“注册”或“同意”按钮，即表示您已充分阅读、理解并接受本协议的全部内容，同意受本协议的约束。阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序。除非您已阅读并接受本协议的所有条款，否则您无权使用本平台的任何部分或全部。<span class="bold underline">您对本平台的任何使用行为，包括但不限于注册、登录、使用本平台的全部或部分功能及服务均视为您已阅读并同意接受本协议全部条款的约束，届时您不应以未阅读本协议的内容或者未获得易服立达对您问询的解答等理由，主张本协议无效，或要求撤销本协议。如您不同意本协议的约定，您应立即停止注册或停止使用本平台的服务。</span></p>
    <p>易服立达有权根据国家法律法规变化及维护交易秩序、保护平台用户权益需要，不时修改本协议并在本平台予以公告。公告期为7天，公告期满后协议即自动生效。如您不同意相关变更，必须停止使用本服务。一旦您继续使用本服务，则表示您已接受并自愿遵守经修订后的条款。</p>
    <p>一、 定义</p>
    <p>1、易服立达：指由北京易服立达科技有限公司开发并运营管理的为注册用户提供服务的第三方中介平台，简称“易服立达”或“本平台”，包含但不限于易服立达平台网站、易服立达下属各类应用程序、易服立达相关小程序、公众号等各类平台。</p>
    <p>2、易服立达服务：简称“本服务”，指由易服立达基于移动互联网，在易服立达平台网站、移动智能终端设备向您提供的各项服务，包括但不限于信息发布、服务推荐、服务搜索与管理、订单管理、费用托管、报酬支付等具体服务。本服务包含的具体服务以易服立达实际提供的服务内容为准。</p>
    <p>3、需求商：指在易服立达上注册，并在易服立达平台上发起服务需求或购买/接受服务，与服务商在平台上建立技术服务关系的用户。</p>
    <p>4、服务商：指在易服立达上注册，并经平台审核通过的具备专业技术知识，在易服立达平台上与需求商建立技术服务关系并根据需求商订单需求向其提供服务的用户。</p>
    <p>5、用户：是指在易服立达平台完成全部注册程序并接受易服立达提供服务的自然人、法人或其他依法成立的组织。</p>
    <p>6、客户：需求商及需求商要求服务商提供服务的最终用户，简称客户。</p>
    <p>7、易服立达平台规则：包括所有易服立达平台发布及后续更新发布的各类规则及公告等内容。</p>
    <p>二、 用户申请条件</p>
    <p>1、<span class="bold">只有符合以下条件之一的自然人、依法成立的法人或其他组织才能成为本平台用户</span>，可以使用本平台服务：</p>
    <p class="indent">1）年满十八岁，并具有与您行为相适应的民事权利能力和民事行为能力的自然人；</p>
    <p class="indent">2）无民事行为能力人或限制民事行为能力人应经过其监护人的同意；</p>
    <p class="indent">3）根据中国法律、法规、行政规章成立并合法存续的法人或其他组织。</p>
    <p>2、用户符合以下条件，经平台审核通过确认后，可申请成为技术需求服务商，向需求商提供信息技术服务：</p>
    <p class="indent">1）对网络数据通信、安全、主机服务器、存储、系统及数据库等相关信息技术有认识、经过系统的培训/学习、具备实际操作的技术能力和资质。</p>
    <p class="indent">2）仔细阅读并接受本平台公布的工程师服务规范，遵守工程师行业通用道德规范，对敏感信息能保守秘密不传播。</p>
    <p class="indent">3）具备完成信息技术服务需求事项所必需的工具（笔记本电脑和串口转换线）和技能（信息技术技能）或特定技术服务所需的信息技术服务相关的资格。</p>
    <p>三、 注册程序及注意事项</p>
    <p>1、您需要在注册时，根据页面的提示信息，准确填写并及时更新您所提供的包括但不限于电话号码、联系地址、邮政编码、资格资质等资料信息。自然人应当同时提供真实姓名、身份证号码，法人组织需提供统一社会信用代码。在您成为易服立达用户后，应提交并绑定用户名下<span class="bold">真实有效的银行借记卡</span>，法人组织需提交并绑定<span class="bold">真实有效的企业对公银行账号</span>，以便完成有关报酬收取、服务购买等事项。易服立达有权要求您根据第三方支付机构及我国法律的规定完成实名认证。</p>
    <p>2、在您签署本协议并按照注册页面提示填写内容、阅读并同意本协议完成注册程序时，<span class="bold">易服立达会向您提供唯一用户名及账号，账户密码由您自行设置并保管</span>。您可以使用您提供或确认的手机号码或易服立达允许的其他方式作为登录手段进入易服立达（当使用手机号码注册时，手机号码也可作为您的账户用户名）。</p>
    <p>3、您应对您的账户及密码、以及对通过您的账户和密码实施的一切行为负责，因您主动泄露或遭受他人攻击、诈骗等行为导致您遭受损失及后果的，易服立达并不承担责任，您应通过司法、行政等救济途径向侵权行为人追偿。</p>
    <p>4、除非有法律法规的明确规定或其他权力机关的强制需求或经得易服立达的同意，否则，<span class="bold">您的账户及密码不得以任何形式进行转让、赠与或继承，如发现任何人不当使用您的账户或有其他可能危及您账户安全的任何情形时，您应立即以有效的方式通知易服立达暂停此账户的相关服务，易服立达对您的请求提出之前已经发生的后果或其他任何损失不承担任何责任。</span></p>
    <p>四、 平台服务事项</p>
    <p>您及易服立达均同意，在您根据本协议注册取得用户名及账户后，可以使用该用户名或账户作为同一账号登录易服立达服务/易服立达接单平台/网站/公众号/小程序等各项服务，并享受相应服务。同时，根据您在不同服务中所承担的角色的不同，应分别作为需求商及服务商遵守下列交易要求：</p>
    <p>1、需求商</p>
    <p class="indent">1）您有权根据本协议及本平台发布的相关规则，利用本平台发布各类信息技术服务需求、进行服务招标等各类服务需求内容，并有权参加本平台的有关活动及享受本平台提供的其他有关资讯及信息服务。</p>
    <p class="indent">2）您在易服立达进行需求发布的行为，即视为您发布交易订单，对本次订单交易发出邀约。</p>
    <p class="indent">3）您发布订单后，即视为您同意易服立达可自行或与有能力的服务机构（该服务商隶属于一家或几家不等的服务机构）合作，由其指派服务商报名您的订单。</p>
    <p class="indent">4）您同意在与报名服务商进行充分的沟通后，与您选定的服务商生成《工作说明书》作为本次交易的具体实施要求，《工作说明书》的生成视为您同意易服立达接受您的订单，选定该服务商向您提供服务。</p>
    <p class="indent">5）《工作说明书》生成后，您需将同意支付的服务费一并支付至易服立达平台托管。支付成功后，服务商开始执行该订单，向您提供相应服务。</p>
    <p class="indent">6）服务完成后，您通过易服立达对服务商提供的服务进行评价、验收，并对服务结果进行验收和确认。在您对服务商所提供服务确认验收通过后，您托管的服务费将由平台支付给服务商。</p>
    <p class="indent">7）需求商对双方已确认验收完毕的交付服务在质保期外产生纠纷或异议，要求服务商退还已支付的服务费的，服务商有权拒绝。</p>
    <p class="indent">8）在您使用易服立达达成交易时，您认可使用您的登陆账号进行订单执行和交易，在易服立达的交易订单信息、使用记录、交易金额、应向易服立达支付的费用等数据均以易服立达记录为准。如因国家法律法规、税务政策、规则变化、系统故障等原因使得易服立达的记录存在偏差或错误，您同意易服立达按实际情况对偏差或错误进行纠正，并按纠正后的正确数据进行结算。</p>
    <p class="indent bold">9）若您在易服立达收取交易手续费时继续使用本服务的，则表示您已完全知晓并接受易服立达收取交易手续费的方案。如您不同意，您应停止使用本服务。</p>
    <p class="indent bold">10）不论您的部分或全部服务是否已暂停或终止，您仍有责任向易服立达支付交易手续费，以及截至服务暂停或终止时所累积的欠款，即使本合同之效力终止，您仍应承担该等付款责任。</p>
    <p>2、服务商</p>
    <p class="indent">1）您同意在通过易服立达审核后，根据本协议及平台发布的相关规则，接受易服立达或易服立达合作服务机构的委派，接受需求商在易服立达平台发布的订单，向其提供信息技术服务。服务机构与服务商间权利义务关系以具体订单签约时，双方签订的协议约定为准，未在协议中进行约定的，以本协议内容为准。</p>
    <p class="indent">2）您同意并认可接受订单后，应按照订单载明的服务内容、工作要求及其他订单内容要求，直接向需求商提供服务，接受服务需求商对所提供服务的评价及成果验收，并由需求商在易服立达系统提交验收确认结果，对确认结果有异议的，按照本协议第五条、第六条约定处理。</p>
    <p class="indent">3）您同意在完成相应服务，经需求商验收成功，并在约定的质保期结束后，由易服立达或易服立达合作服务机构在平台付款周期内，将相应服务报酬按国家规定扣除相应税费后，支付到您在易服立达登记的个人账户或关联的银行账号中。因银行扣款等原因无法及时支付完毕的，具体支付完成时间以实际银行到账的时间为准。</p>
    <p class="indent">4）<span class="bold">在您使用易服立达达成交易时，您认可使用您的登陆账号，在易服立达的交易订单信息、使用记录、交易金额、代扣代缴税费等数据均以易服立达记录为准。</span>如因国家法律法规、税务政策、规则变化、系统故障等原因使得易服立达的记录存在偏差或错误，您同意易服立达按实际情况对偏差或错误进行纠正，并按纠正后的正确数据进行结算。</p>
    <p class="indent bold">5）服务商应确认具备完成订单工作所需技能（信息技术技能），自备完成订单事项所必需的工具（笔记本电脑和串口转换线等），以合理的谨慎和精力以确保您完成任务事项过程中不对自己和他人的人身财产等权益造成损害；如由于您的故意或过失造成损害的，相关风险和责任由您自行承担；同时您应自行承担在履行任务的过程中与需求商发生的任何责任。由此给易服立达造成损失的，易服立达有权向您追偿或要求予以赔偿。</p>
    <p class="indent bold">6）同时您同意由您自行承担为履行任务而发生的任何意外事件的责任，及在履行任务的过程中与需求商发生的任何责任。由此给易服立达造成损失的，易服立达有权向您追偿或要求予以赔偿。</p>
    <p>3、服务费用</p>
    <p class="indent">1）在您享受易服立达提供的服务时，您同意易服立达根据您的服务内容或交易收取相关服务费用并有权制订及调整交易手续费。变更后的收费规则由易服立达及时通过本台发布使您知悉，确保您有充分选择的权利。</p>
    <p class="indent bold">2）需求商知悉并同意易服立达将按一定规则向您收取交易服务手续费，该交易手续费规则将在您发起需求时向您显示，需由您在托管服务费时同时支付给易服立达。您在平台提交需求即视为您认可该手续费规则。</p>
    <p class="indent">3）服务商知悉并同意在收取服务费时，由易服立达所指定的服务机构按照国家法律法规和政策要求扣除代扣代缴的相应税费后将剩余款项支付给服务商。</p>
    <p class="indent bold">4）您同意易服立达有权从您的账户余额或其他资产中直接扣除以上交易服务手续费、代扣代缴相应税费而无须您事先同意。</p>
    <p class="indent">5）与交易订单相关发票，由需求商向易服立达申请开具。申请时需认真填写开票信息。开票信息包括：公司名称、企业税号、注册地址及电话、开户行及账号；或个人名称，除个人、事业单位或政府机关、个体工商户、社会团体等非企业单位无须提供税号，其余均必须填写企业纳税人识别号。</p>
    <p>五、 用户的权利与义务</p>
    <p>1、您应保证向易服立达提供真实有效的注册信息或平台需要的其他资料，包括但不限于真实姓名、身份证号、联系电话、地址、技能证书等。您应保证上述信息的真实性，保证易服立达可以通过上述联系方式与您进行联系。同时，您也应当及时在相关资料实际变更时及时更新有关注册资料。</p>
    <p class="bold">2、您不得冒用他人或第三方机构的名称进行注册，或以虚假信息骗取注册，账号名称、账号头像、简介等注册信息不得存在违法和不良信息。</p>
    <p>3、您在易服立达上发布的各项信息技术供应或投标信息等与易服立达提供服务相应的其他信息，不得违反国家法律、法规、行政规章等的规定，不得侵犯他人知识产权或其他合法权益，不得违背社会公共利益或公共道德，不得违反易服立达的相关规定。否则，您将以自己的名义独立承担相应的法律责任。易服立达有权对相应的信息内容进行修改、取消、禁止显示、删除等操作，有权对相关订单采取暂停、取消、作废、删除等操作，有权对您的账号采取短期停用、长期禁用、注销等操作，由此导致您的损失的，由您自行承担，给他人或本平台造成损失的，还应予以赔偿。</p>
    <p>4、您应在使用易服立达时，遵守诚实信用原则，不得以采用任何不正当竞争的方式扰乱网上交易秩序，不得从事与网上交易无关的任何行为。您的购买行为应当基于真实的消费需求，不得存在对商品及/或服务实施恶意发布、恶意购买、恶意维权等扰乱易服立达正常交易秩序的行为。基于维护易服立达交易秩序及交易安全的需要，易服立达发现上述情形时可主动执行关闭相关交易订单等操作，被关闭交易订单对应托管服务费，服务商及需求商均同意作为上述行为的惩罚性违约金，不再要求平台予以退还。</p>
    <p>5、如服务商为执行需求商的服务需求已实际支出费用后（包括但不限于执行该订单相关的差旅费、交通费、为执行该订单专门进行的设备/软件/工具/材料采购等），需求商取消签约订单的，经易服立达核对服务商的该等费用支出属实的，需求商应同意平台可从已托管的服务费中支取相应的金额弥补服务商的费用损失。</p>
    <p>6、您在易服立达进行交易过程中与其他用户发生争议的，您或其他用户中任何一方均有权选择以下途径解决：</p>
    <p class="indent">1）与争议相对方自主协商；</p>
    <p class="indent">2）使用易服立达提供的争议调处服务；</p>
    <p class="indent">3）请求消费者协会或者其他依法成立的调解组织调解；</p>
    <p class="indent">4）向有关行政部门投诉；</p>
    <p class="indent">5）根据与争议相对方达成的仲裁协议（如有）提请仲裁机构仲裁；</p>
    <p class="indent">6）提起诉讼解决。</p>
    <p>您如果发现其他用户有违法或违反本协议的行为，选择向易服立达举报，请求易服立达依据易服立达规则进行争议调处服务，则表示您认可并愿意履行易服立达的客服作为独立的第三方根据其所了解到的争议事实并依据易服立达规则所作出的调处决定（包括调整相关订单的交易状态、判定将争议款项的全部或部分支付给交易一方或双方等）。在易服立达调处决定作出前，您可选择上述3）、4）、5）、6）途径（下称“其他争议处理途径”）解决争议以中止易服立达的争议调处服务。</p>
    <p>如您对调处决定不满意，您仍有权采取其他争议处理途径解决争议，但通过其他争议处理途径未取得终局决定前，您仍应先履行调处决定。</p>
    <p>7、您不得使用以下方式登录平台或破坏平台所提供的服务：</p>
    <p class="indent">1）以任何机器人软件、蜘蛛软件、爬虫软件、刷屏软件和其它自动方式访问或登录本平台；</p>
    <p class="indent">2） 通过任何方式对本公司内部结构造成或可能造成不合理或不合比例的重大负荷的行为；</p>
    <p class="indent">3）通过任何方式干扰或试图干扰平台的正常工作或平台上进行的任何活动；</p>
    <p class="indent">4）使用非法手段获取的他人账号登陆的。</p>
    <p>8、您有权在同意本平台相关规则的前提下享受消费者保障服务，并应对您销售额/营业额/收入超过法定免征额部分及时、足额地向税务主管机关申报纳税。</p>
    <p>9、您在使用易服立达进行相关活动时，须承诺遵守法律法规、国家制度、公民合法权益、公共秩序、社会道德风尚。</p>
    <p>六、 易服立达的权利与义务</p>
    <p class="bold">1、易服立达仅为用户提供一个信息交流平台及服务平台，是需求商发布需求和服务商选择提供服务的平台，易服立达在提供平台服务的同时，不对交易双方加以监视和控制，亦不主动介入撮合双方达成交易的过程。</p>
    <p>2、易服立达有义务在现有技术水平的基础上努力确保整个网上交流平台的正常运行，尽力避免服务中断或将中断时间限制在最短时间内，保证用户网上交流活动的顺利进行。</p>
    <p>3、易服立达有义务对用户在注册使用易服立达信息平台中所遇到的与交易或注册有关的问题及反映的情况及时作出回复。</p>
    <p>4、易服立达有权对用户的注册资料进行审查，对存在任何问题或怀疑的注册资料，易服立达有权发出通知询问用户并要求用户做出解释、改正。</p>
    <p>5、用户因在易服立达网上交易与其他用户产生纠纷的，用户应及时将纠纷告知易服立达，或易服立达主动获悉纠纷情况的，易服立达有权通过电子邮件及电话联系向纠纷双方了解纠纷情况，并将所了解的情况通过电子邮件互相通知对方用户；用户通过司法机关依照法定程序要求易服立达提供相关资料，易服立达将积极配合并提供有关资料。</p>
    <p>6、因网上信息平台的特殊性，易服立达不承担对所有用户的交易行为以及与交易有关的其他事项进行事先审查的义务，但如发生以下情形，易服立达有权无需征得用户的同意限制用户的活动、向用户核实有关资料、发出警告通知、暂时中止、无限期中止及拒绝向该用户提供服务：</p>
    <p class="indent">1）用户以非自然人名义进行认证之后认证主体自行注销或者经有关机关吊销或撤销的；</p>
    <p class="indent">2）用户违反本协议或本平台发布的相关规则；</p>
    <p class="indent">3）用户或其他第三方通知易服立达，认为某个用户或具体交易事项存在违法或不当行为，并提供相关证据，而易服立达无法联系到该用户核证或验证该用户向易服立达提供的任何资料；</p>
    <p class="indent">4）用户或其他第三方通知易服立达，认为某个用户或具体交易事项存在违法或不当行为，并提供相关证据。易服立达以普通非专业人员的知识水平标准对相关内容进行判别，可以明显认为这些内容或行为可能对他方或易服立达造成侵害的。</p>
    <p>7、根据国家法律、法规、行政规章规定、本协议的内容和易服立达所掌握的事实依据，可以认定该用户存在违法或违反本协议行为以及在易服立达交易平台上的其他不当行为，易服立达有权无需征得用户的同意，即可在易服立达交易平台及所在平台上以网络发布形式公布该用户的违法行为，并有权随时作出删除相关信息、终止提供服务等处理。</p>
    <p class="bold">8、易服立达依据本协议及相关规则，可以冻结、使用、先行赔付、退款、处置用户缴存并冻结在易服立达账户内的资金。因违规而被封号的用户账户中的资金在按照规定进行处置后尚有余额的，该用户可申请提现。</p>
    <p>9、易服立达有权根据用户的服务内容及交易内容收取相应的服务费用，收费规则由易服立达采取合理途径并以足够合理的期限提前通过平台通知的形式通知用户。不论您的部分或全部服务是否已经暂停或终止，在用户达成确认订单后，用户即负有向易服立达支付服务费用的责任，不因订单交易的终止或解除而免除，已经收取的服务费，易服立达不因订单的取消或解除而退还。</p>
    <p>10、易服立达有权在不通知用户的前提下，删除或采取其他限制性措施处理下列信息，包括但不限于：以规避费用为目的；以炒作信用为目的；存在欺诈等恶意或虚假内容；与网上交易无关或不以交易为目的；存在恶意竞价或其他试图扰乱正常交易秩序因素；违反公共利益或可能严重损害易服立达和其他用户合法利益。</p>
    <p>七、 本协议的生效、变更及终止</p>
    <p>1、在您按照注册页面提示填写相关信息，阅读并同意本协议，完成注册程序后或使用其他方式实际使用易服立达服务平台时，即视为您已知晓并同意本平台的各项注册及使用规则。</p>
    <p class="bold">2、易服立达有权随时变更本协议及使用规则并在本平台予以公告，经修订的条款在本平台公布后立即自动生效。如您不同意相关变更，必须停止使用本平台。一旦您继续使用本平台，则表示您已接受并自愿遵守经修订后的条款。</p>
    <p>3、您在使用平台服务时，应对您通过易服立达获取或知悉的包括但不限于用户信息、平台信息、需求商业务信息/设备信息/应用系统信息、需求商服务需求和内容、服务费、费用支付等相关信息进行保密。如您违反平台约定的相应的保密义务，易服立达有权终止本协议，并保留追究您法律责任的权利。</p>
    <p>4、您有权向易服立达要求注销您的账户，经易服立达审核同意后，易服立达将注销您的账户，届时，您与易服立达基于本协议的合同关系即终止。您的账户被注销后，易服立达没有义务为您保留或向您披露您账户中的任何信息，也没有义务向您或任何第三方转发您未曾阅读或发送过的任何信息。</p>
    <p class="bold">5、如您存在下列情况，易服立达有权终止向您提供服务，由此导致您在使用易服立达过程中产生的任何损失或费用由您独自全部承担：</p>
    <p class="indent bold">1）在您违反本协议及易服立达已公示的相关规则规定时，易服立达有权终止向您提供服务。</p>
    <p class="indent bold">2）易服立达发现您注册资料中主要内容是虚假的，则有权随时终止为您提供全部或部分服务；</p>
    <p class="indent bold">3）本协议终止或更新时，您未确认新的协议的；</p>
    <p class="indent bold">4）其它易服立达认为需终止服务的情况。</p>
    <p>八、责任范围</p>
    <p>当您接受该协议时，您应当明确了解并同意：</p>
    <p class="bold">1、易服立达不能随时预见到任何技术上的问题或其他困难。该困难可能会导致数据损失或其他服务中断。易服立达是在现有技术基础上提供的服务。易服立达不保证以下事项：</p>
    <p class="bold indent">1）易服立达不受干扰、能够及时提供、安全可靠或免于出错；</p>
    <p class="bold indent">2）本服务使用权的取得结果是正确或可靠的。</p>
    <p>2、您经由易服立达取得的建议和资讯，无论其形式或表现，绝不构成本协议未明示规定的任何保证。</p>
    <p>3、基于以下原因而造成的利润、商誉、使用、资料损失或其它无形损失，易服立达不承担任何直接、间接、附带、特别、衍生性或惩罚性赔偿（即使易服立达已被告知赔偿的可能性）：</p>
    <p class="indent">1）使用易服立达或在易服立达已尽善意管理的情况下，因常规或紧急的设备与系统维护、设备与系统故障、网络信息与数据安全等因素导致的无法使用；</p>
    <p class="indent">2）您的传输或资料遭到未获授权的存取或变更；</p>
    <p class="indent">3）易服立达中任何第三方之声明或行为；</p>
    <p class="indent">4）易服立达在服务交易中为您提供交易机会，推荐交易方；</p>
    <p class="indent">5）易服立达其它相关事宜。</p>
    <p class="bold">4、易服立达仅为用户提供一个服务交易的平台。为保障工程师用户的权益，平台推荐工程师用户购买相应保险保障等服务，工程师用户在订单执行过程中产生的意外损失可根据保险条款或细则维护自身相关权益，如果工程师用户自身未购买保险，基于工程师用户作为个体承接工作任务，所产生的意外将由工程师用户自行承担相关责任。</p>
    <p>5、易服立达提供与其它互联网上的平台或资源的链接，您可能会因此连结至其它运营商经营的平台，但不表示易服立达与这些运营商有任何关系。其它运营商经营的平台均由各经营者自行负责，不属于易服立达控制及负责范围之内。对于来源于此类平台或资源的任何内容、广告、物品或其它资料，易服立达亦不予保证或负责。因使用或依赖任何此类平台或资源发布的或经由此类平台或资源获得的任何内容、物品或服务所产生的任何损害或损失，易服立达不负任何直接或间接的责任。</p>
    <p>九、 知识产权</p>
    <p>1、易服立达及易服立达所使用的任何相关软件、程序、内容，包括但不限于作品、图片、档案、资料、平台构架、平台版面的安排、网页设计、经由易服立达或广告商向您呈现的广告或资讯，均由易服立达或其它权利人依法享有相应的知识产权，包括但不限于著作权、商标权、专利权或其它专属权利等，受到相关法律的保护。未经易服立达或其他相关权利人明示授权，您保证不修改、出租、出借、出售、散布易服立达及易服立达所使用的上述任何资料和资源，或根据上述资料和资源制作成任何种类产品。</p>
    <p>2、易服立达授予您不可转移及非专属的使用权，使您可以通过单机（计算机或移动设备）使用易服立达的目标代码（以下简称“软件”），但您不得且不得允许任何第三方复制、修改、创作衍生作品、进行还原工程、反向组译，或以其它方式破译或试图破译源代码，或出售、转让“软件”或对“软件”进行再授权，或以其它方式移转“软件”之任何权利。您同意不以任何方式修改“软件”，或使用修改后的“软件”。</p>
    <p>3、您不得经由非易服立达所提供的界面使用易服立达。</p>
    <p>十、 隐私权</p>
    <p>1、信息使用</p>
    <p class="indent">1）易服立达不会向任何人出售或出借您的个人或法人信息，除非事先得到您得许可；</p>
    <p class="indent">2）易服立达亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人或法人信息。任何您如从事上述活动，一经发现，易服立达有权立即终止与您的服务协议，查封您的账号。</p>
    <p class="indent">3）您同意易服立达为进行身份验证、收入支付、提现或退款，可向银行、税务、第三方支付机构及其他相关第三方机构提供所必需的信息。</p>
    <p class="indent">4）您同意易服立达及其关联第三方机构存储、使用、复制、修订、编辑、发布、展示、翻译、分发您的非个人信息或制作其派生作品，并以已知或日后开发的形式、媒体或技术将上述信息纳入其它作品内。</p>
    <p>2、信息披露</p>
    <p>您的个人或法人信息将在下述情况下部分或全部被披露：</p>
    <p class="indent">1）经您同意，向第三方披露；</p>
    <p class="indent">2）您在使用易服立达过程中涉及到知识产权类纠纷，有他方主张权利的，易服立达在审核主张方提交的资料后认为披露您信息有助于纠纷解决的；</p>
    <p class="indent">3）根据法律的有关规定，或者行政、司法机关的要求，向第三方或者行政、司法机关披露；</p>
    <p class="indent">4）若您出现违反中国有关法律或者平台规定的情况，需要向第三方披露；</p>
    <p class="indent">5）为提供你所要求的产品和服务，而必须和第三方分享您的个人或法人信息；</p>
    <p class="indent">6）为保护您、易服立达和其他关联方的合法权益，我们可能将您的个人信息用于预防、发现、调查以下事项：欺诈、危害安全、违法、违反易服立达或其关联方协议、政策或规则的行为；</p>
    <p class="indent">7）在遵循隐私权保护以及其他相应的保密安全措施的前提下，允许我们将您的个人信息提供给相关合作方，让其根据我方指令处理相关信息；</p>
    <p class="indent">8）其它易服立达根据法律或者平台规定认为合适的披露。</p>
    <p>若您不同意以上内容，请立即停止使用易服立达服务。</p>
    <p>您或者第三方申请易服立达披露其他信息的，易服立达有权视实际情况要求申请人出具申请书，申请书内容应包含申请披露的信息范围、用途及使用承诺等内容。</p>
    <p>3、信息安全</p>
    <p class="indent">1）在使用易服立达服务进行网上交易时，请您妥善保护自己或因交易获得的他人的信息，仅在必要的情形下向他人提供；</p>
    <p class="indent">2）如果您发现自己的用户信息泄密，尤其是您账号或“账户管理”中的账户及密码发生泄露，请您立即联络易服立达客服，以便我们采取相应措施。</p>
    <p class="indent">3）您应当确保您所发布的信息不包含以下内容：</p>
    <p class="indent">（一）违反国家法律法规禁止性规定的；</p>
    <p class="indent">（二）政治宣传、封建迷信、淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</p>
    <p class="indent">（三）欺诈、虚假、不准确或存在误导性的；</p>
    <p class="indent">（四）侵犯他人知识产权或涉及第三方商业秘密及其他专有权利的；</p>
    <p class="indent">（五）侮辱、诽谤、恐吓、涉及他人隐私等侵害他人合法权益的；</p>
    <p class="indent">（六）存在可能破坏、篡改、删除、影响易服立达任何系统正常运行或未经授权秘密获取易服立达及其他用户的数据、个人资料的病毒、木马、爬虫等恶意软件、程序代码的；</p>
    <p class="indent">（七）其他违背社会公共利益或公共道德或依据相关易服立达协议、规则的规定不适合在易服立达上发布的。</p>
    <p class="indent">4）您确认并承诺您不会违反下列情况或网络安全要求：</p>
    <p>1、未经客户许可，不得使用个人便携设备等介质访问未授权网络区域;</p>
    <p>2、未经客户书面授权，不得访问客户系统，收集、持有、处理、修改客户网络中的任何数据和信息;</p>
    <p>3、 不得进行超出客户审批范围内的任何操作;</p>
    <p>4、 如果需要进行远程协助或登录设备操作，则需要在获得远程服务对象同意并授权后，再具体实施；并且对于接触的相关客户信息，如客户远程登录方式、登录账号、客户IP地址等信息注意保密，不允许扩散；不得使用他人账号或未授权账号登录设备进行操作;</p>
    <p>5、不得攻击、破坏客户网络，破解客户账户密码;</p>
    <p>6、未经客户许可不得泄漏和传播客户的数据和信息;</p>
    <p>7、 不得利用客户系统的信息和数据谋取个人利益或违反其他法律法规。</p>
    <p>您确认并承诺您不会违反下列情况或信息安全要求：</p>
    <p>1、 绝不出卖所接触到的易服立达平台及客户的保密信息（以下简称“保密信息”），不将以上保密信息发送/上传至外部邮箱/论坛/网盘或其他个人或公共网络环境;</p>
    <p>2、 服务完结或中止合作时，不以任何形式获取、带走、泄露保密信息;</p>
    <p>3、 不私自拍摄、录音、录像、截屏客户保密信息;</p>
    <p>4、 不通过任意方式拷贝保密信息到任何私人介质;</p>
    <p>5、 不在客户设备上私自安装、卸载或停用客户软件；</p>
    <p>6、 未经客户许可，不私自拆卸和维修物理设备;</p>
    <p>7、 未经授权，不得将保密信息（含内部公开信息）例如邮件、纪要、发文等信息传播至互联网，或者在互联网讨论中引用、泄露上述信息;</p>
    <p>十一、 不可抗力</p>
    <p>因不可抗力或者其他意外事件，使得本协议的履行不可能、不必要或者无意义的，双方均不承担责任。本协议所称之不可抗力意指不能预见、不能避免并且不能克服的客观情况，包括但不限于战争、台风、水灾、火灾、雷击或地震、罢工、暴动、法定传染病、黑客攻击、网络病毒、电信部门技术管制、政府行为或任何其它自然或人为造成的灾难等客观情况。</p>
    <p class="bold">十二、 保密</p>
    <p class="bold">您保证在使用易服立达过程中所获悉的属于易服立达及其他方的且无法自公开渠道获得的文件及资料（包括但不限于商业秘密、公司计划、运营活动、财务信息、技术信息、经营信息及其他商业秘密）予以保密。未经该资料和文件的原提供方同意，您不得向第三方泄露该商业秘密的全部或者部分内容。但法律、法规、行政规章另有规定或者双方另有约定的除外。</p>
    <p class="bold">十三、 赔偿责任</p>
    <p class="bold">如您的行为使易服立达及或其关联公司、第三方机构遭受损失（包括自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失），您应赔偿易服立达或其关联公司、第三方机构的上述全部损失。如您的行为使易服立达或其关联公司、第三方机构遭受第三人主张权利，易服立达及/或其关联公司可在对第三人承担金钱给付等义务后就全部损失向您追偿。</p>
    <p class="bold">如因您的行为使得第三人遭受损失或您怠于履行调处决定、易服立达或其关联公司、第三方机构出于社会公共利益保护或消费者权益保护目的，可由易服立达或易服立达指示第三方支付机构自您的账户中划扣相应款项进行支付。</p>
    <p class="bold">如您的账户余额或保证金不足以支付相应款项的，我方可介入与您协商及时充值，以便划扣相应款项，您如逾期不充值，我方可采取终止合同、停止使用部分或全部服务、锁定账号等措施。</p>
    <p class="bold">您同意易服立达或易服立达指示第三方支付机构自您的账户中划扣相应款项支付上述赔偿款项。如您账户中的款项不足以支付上述赔偿款项的，易服立达及/或关联公司可直接抵减您在易服立达及/或其关联公司其它协议项下的权益，并可继续追偿。</p>
    <p class="bold">十四、 交易纠纷解决方式</p>
    <p class="bold">本协议及其规则的有效性、履行和与本协议及其规则效力有关的所有事宜，将受中华人民共和国法律约束，任何争议仅适用中华人民共和国法律。</p>
    <p class="bold">易服立达有权受理并调处您与其他方因交易服务产生的纠纷，同时有权单方面独立判断其他方对您的举报及索偿是否成立，若判断索偿成立，则易服立达有权代其他方对您进行相应索偿。易服立达没有使用自用资金进行偿付的义务，但若进行了该等支付，您应及时赔偿易服立达的全部损失，否则易服立达有权通过其他方式抵减相应资金或权益，如仍无法弥补损失，则易服立达保留继续追偿的权利。因易服立达非司法机关，您完全理解并承认，易服立达对证据的鉴别能力及对纠纷的处理能力有限，受理交易纠纷完全是基于您之委托，不保证处理结果符合您的期望，易服立达有权决定是否参与争议的调处。</p>
    <p class="bold">凡因履行本协议及其规则发生的纠纷以及在易服立达网上交易与易服立达运营者产生的所有纠纷，各方可协商解决，若协商不成的，各方一致同意提交北京仲裁委员会按其仲裁规则进行仲裁。</p>
    <p>十五、 其他</p>
    <p>本协议由本协议条款及易服立达公示的各项规则组成，相关名词可互相引用参照，如有不同理解，以本协议条款为准。</p>
    <p>您对本协议的理解和认同，等同于对本协议所有组成部分的内容理解并认同，一旦您使用本平台服务，您即受本协议所有组成部分的约束。</p>
    <p>易服立达对本服务协议包括基于本服务协议制定的各项规则拥有最终解释权。</p>
  </div>
</template>

<script>
export default {
  name: "UserAgreement",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.container {
  padding: 16px;
  .indent{
    text-indent: 1.5em;
  }
  p {
    font-size: 14px;
    line-height: 18px;
    color: #333;
    margin-bottom: 16px;
    text-align: justify;
  }
  .bold {
    font-weight: bold;
  }
  .underline{
    text-decoration: underline;
  }
}
</style>
